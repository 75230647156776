var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumbs',{attrs:{"page_title":"library","items":_vm.breadcrumbs}}),[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.subItems),function(item,i){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(item.show),expression:"item.show"}],key:i,staticClass:"library lg5-custom",attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[(item.show)?_c('v-card',{staticClass:"mx-auto library_card pa-4",staticStyle:{"border-radius":"16px","padding-top":"12px"},attrs:{"elevation":"3"},on:{"click":function($event){_vm.$router.push({
                path:
                  '/library/' +
                  item.topicName.split('?').join('') +
                  '/' +
                  item.name,
                query: { topic_id: item.id },
              })}}},[_c('v-img',{staticClass:"library_img",attrs:{"gradient":"to top right, rgba(66,66,66,.43), rgba(66,66,66,.43)","src":item.image,"height":"160px"}},[_c('v-card-title',{staticClass:"cardTitle"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-img',{staticClass:"icons",attrs:{"src":item.icon,"width":"70","height":"70"}})],1)],1):_vm._e()],1)}),1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }