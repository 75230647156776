<template>
  <div>
    <Breadcrumbs page_title="library" :items="breadcrumbs" />
    <template>
      <v-container fluid>
        <v-row>
          <v-col
            v-show="item.show"
            cols="12"
            sm="6"
            md="3"
            lg="2"
            v-for="(item, i) in subItems"
            :key="i"
            class="library lg5-custom"
          >
            <v-card
              v-if="item.show"
              class="mx-auto library_card pa-4"
              style="border-radius: 16px; padding-top: 12px"
              elevation="3"
              @click="
                $router.push({
                  path:
                    '/library/' +
                    item.topicName.split('?').join('') +
                    '/' +
                    item.name,
                  query: { topic_id: item.id },
                })
              "
            >
              <v-img
                gradient="to top right, rgba(66,66,66,.43), rgba(66,66,66,.43)"
                :src="item.image"
                height="160px"
                class="library_img"
              >
                <v-card-title class="cardTitle">
                  {{ item.title }}
                </v-card-title>
                <v-img :src="item.icon" width="70" height="70" class="icons" />
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: true,
      userID: "",
      companyID: "",
      items: [],
    };
  },
  computed: {
    // ...mapGetters(["editLibraryData"]),
    subItems() {
      return [
        {
          image: require("@/assets/close-up-female-hand-using-digital-tablet-tablet@2x.png"),
          icon: require("@/assets/images/Group 9743.svg"),
          title: this.$t("article"),
          name: "Article",
          topicName: localStorage.getItem("libraryName"),
          show: this.$route.query.itemCount.articleCount,
        },
        {
          image: require("@/assets/portrait-female-work-having-video-call@2x.png"),
          icon: require("@/assets/images/Group 9740.svg"),
          title: this.$t("sound"),
          name: "Sound",
          topicName: localStorage.getItem("libraryName"),
          show: this.$route.query.itemCount.soundCount,
        },
        {
          image: require("@/assets/white-office-desk-with-hand-touching-mobile-phone@2x.png"),
          icon: require("@/assets/images/Combined-Shape.svg"),
          title: this.$t("video"),
          name: "Video",
          topicName: localStorage.getItem("libraryName"),
          show: this.$route.query.itemCount.videoCount,
        },
      ];
    },
    breadcrumbs() {
      return [
        // {
        //   sidebar_tilte: "home"
        // },
        {
          text: "library",
          href: "/library",
        },
      ];
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style>
@media (min-width: 1440px) and (max-width: 2560px) {
  .lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
</style>

<style scoped>
.cardTitle {
  position: relative;
  color: #fff;
  font-size: 23px;
  justify-content: center;
  top: 40px;
}
.icons {
  position: absolute;
  bottom: -13px;
  right: -10px;
}
</style>
